@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .wapper_small {
    @apply max-w-screen-lg m-auto;
  }

  .__content {
    @apply p-4 bg-white rounded shadow;
  }

  .footer_modal {
    padding-top: 10px;
    @apply flex justify-end gap-4 border-t;
  }

  .shadow_antd {
    @apply bg-white cursor-pointer rounded p-2 space-y-2;
    box-shadow:
      0 3px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }
  .show__sider {
    flex: 0 0 260px;
    max-width: 260px;
    min-width: 260px;
    width: 260px;
  }
  .hide__sider {
    flex: 0 0 88px;
    max-width: 88px;
    min-width: 88px;
    width: 88px;
  }
  .menu__item {
    @apply h-11 px-8 flex items-center gap-4 ease-in-out duration-200;
  }
}

.__bg {
  background: linear-gradient(0deg, #253F38, #253F38),
    linear-gradient(0deg, #253F38, #253F38);
}
.__text {
  color: #2C4036;
}
.w-btn {
  width: 200px;
}
input:disabled .ant-input.ant-input-disabled, .ant-select-disabled .ant-select-selection-item, .ant-input-number-disabled .ant-input-number-input, .ant-radio-wrapper.ant-radio-wrapper-disabled span, .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
  color: rgba(0, 0, 0, 0.88) !important;
}

.ant-btn-primary {
  background: #253F38 !important;
}

.ant-picker-now-btn {
  display: none;
}

.ant-pagination-item-link {
  color: #2C4036 !important;
}

.ant-pagination-item-active {
  background-color: #F6F8F6 !important;
  border-color: #2C4036 !important;
}

/* .ant-select-selector {
  border: 1px solid #2C4036 !important;
  background-color: #F6F8F6 !important;
} */

.icon_color {
  color: #397E79;

}

.custom_white{
  background-color:#F7F6F0 !important;
}

.custom_bg_white {
  background-color:#F2F4F1 !important;
}

.ant-pagination{
  color: #2C4036 !important;
}

.box_color{
  color: #253F38 !important;
}

.ant-table-header table th {
  background-color: #397E79 !important;
  color: #FFFFFF !important;
}

*:disabled {
  color: #FFFFFF !important;
  opacity: 1;
}

input:disabled {
  color: #000 !important;
}

.ant-btn-primary:hover {
  background: #253F38CC !important;
}

.ant-btn-default:disabled {
  color: rgb(0 0 0) !important;
}